import config from '../data/config.json';

class LanguageSelector {
    constructor(element) {
        this.element = element;
        this.selectedText = this.element.querySelector('.language-selector__selected__text');
        this.selectedLocale = this.selectedText.innerHTML;
        this.items = this.element.querySelectorAll('.language-selector__list__item');

        if (this.items.length <= 1)  {
            this.element.remove();
            return;
        }

        this.element.addEventListener('click', this.onClick.bind(this));
        this.items.forEach(item => {
            item.addEventListener('click', this.onItemClick.bind(this));
        });
    }

    onClick(event) {
        event.stopPropagation();
        this.element.classList.add('is-open');

        window.onclick = (event) => {
            if (!event.target.matches('.language-selector')) {
                this.hide();
            }
        }
    }

    onItemClick(event) {
        event.stopPropagation();
        this.hide();
        const locale = event.target.getAttribute('data-locale');
        const newUrl = this.getUrlForLocale(locale);

        this.selectedLocale = locale;
        this.selectedText.innerHTML = locale;

        localStorage.setItem('jetconf-language', locale);

        window.location.href = newUrl;
    }

    getUrlForLocale(locale) {
        const url = new URL(window.location.href);
        const currentLocalePrefix = '/' + this.selectedLocale;
        const newLocalePrefix = locale === config.mainLocale ? '' : '/' + locale;
        if (url.pathname.indexOf(currentLocalePrefix) >= 0) {
            url.pathname = url.pathname.replace(currentLocalePrefix, newLocalePrefix)
        }
        else {
            url.pathname = newLocalePrefix;
        }

        return url.toString();
    }

    hide() {
        this.element.classList.remove('is-open');
    }
}

export {
    LanguageSelector
}
