export function initOrganizers2() {
    const items = [...document.querySelectorAll('.organizer2__features .organizer__feature')];

    items.forEach(item => {
        item.addEventListener('mouseover', () => {
            if (item.classList.contains('is-active')) return;

            items.forEach(item => item.classList.remove('is-active'))
            item.classList.add('is-active');
        })
    })
}
