import {Notification} from "./notification";
import {globals} from "./globals";
import {getPointsLocale} from "./points-locale";

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function setInputFilter(input, inputFilter) {
  ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
    input.addEventListener(event, function() {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  });
}

export const connectForm = () => {
  const form = document.querySelector('.connect-form__fields');
  const closeBtn = document.querySelector('.connect-form-close');

  if (!form) return;

  const submitBtn = form.querySelector('button');
  const errorsContainer = form.querySelector('.connect-form__errors');

  const fullNameInput = form.querySelector('#full_name');
  const textInput = form.querySelector('#text');
  const emailInput = form.querySelector('#email');
  const recaptchaInput = form.querySelector('#recaptcha');
  const notification = new Notification('.success-demo-notification');

  function clearData() {
    [fullNameInput, textInput, emailInput, recaptchaInput].forEach(item => {
      item.value = '';
    });
  }

  function showError(errors) {
    errorsContainer.innerHTML = '';

    const errorsList = (Array.isArray(errors) ? [...errors] : [errors])
                      .map(item => typeof item == 'object' ? Object.values(item)[0] : item);

    const ul = document.createElement('ul');
    errorsList.forEach(item => {
      const li = document.createElement('li');
      li.innerHTML = item;

      ul.append(li);
    })

    errorsContainer.append(ul);
  }

  function toggleLoading() {
    if (submitBtn.disabled) {
      submitBtn.disabled = false;
      submitBtn.classList.remove('is-loading');
    }
    else {
      submitBtn.disabled = true;
      submitBtn.classList.add('is-loading');
    }
  }

  function sendData() {
    errorsContainer.innerHTML = '';

    const searchParams = new URLSearchParams();

    [fullNameInput, textInput, emailInput, recaptchaInput].forEach(item => {
      if(item) {
        searchParams.set(item.name, item.value);
      }
    });

    searchParams.set("points", (globals.points ?? 0).toString());

    const http = new XMLHttpRequest();

    // http.withCredentials = true;

    http.open('POST', FORM_URL, true);

    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    // http.setRequestHeader('Access-Control-Allow-Origin', '*');

    http.onreadystatechange = function() {
      if(http.readyState == 4 && http.status == 200) {
        const response = JSON.parse(http.responseText);

        if (response.hasOwnProperty('result') && response.result === false && response.errors) {
          showError(response.errors);
        }
        else {
          closeBtn.click();
          clearData();
          notification.show();
        }
      }

      toggleLoading();
    }

    http.onerror = function (e) {
      console.log(e)
      showError(['Failed to execute the server request, please try again after some time.']);
    }

    http.send(searchParams.toString());
  }


  function validate() {
    let errors = [];
    if (!fullNameInput.value) {
      errors.push('Field "Name" is required');
    }
    if (!emailInput.value) {
      errors.push('Field "Email" is required');
    }
    else if (!validateEmail(emailInput.value)) {
      errors.push('The entered email has an incorrect format');
    }

    return errors;
  }

  function submit() {
    const validationResult = validate();

    if (validationResult && validationResult.length > 0) {
      showError(validationResult);
      return;
    }

    toggleLoading();

    grecaptcha.ready(function() {
      grecaptcha.execute('6LcbY88oAAAAAMcoWkoSjhO6q_QLRGX_I0DbAo3u', {action: 'submit_demo'}).then(function(token) {
        recaptchaInput.value = token;
        sendData();
      });
    });
  }

  form.addEventListener('submit', (event) => {
    event.preventDefault();
    submit();
  }, true)
}
